import React, { useState, useEffect } from "react";
import { useNavigate, Link, Navigate } from "react-router-dom";
import axios from "axios";
import "./register.css";
import Navbar from "./Navbar";
function RegistrationForm({ dbData, setData }) {
  let token = JSON.parse(localStorage.getItem("adminWrightInvestmentGrp"));
  const [success, setsuccess] = useState("");
  const [error, seterror] = useState("");
  let [state, setState] = useState({
    name: "",
    lastname: "",
    email: "",
    company: "",
    amount: "",
    password: "",
    gender: "",
    releaseDate: "",
  });

  useEffect(() => {
    axios
      .get("https://astorgrp.vercel.app/user")
      .then((response) => {
        if (response?.data) {
          setData(response?.data);
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {});
  });

  const setValues = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setState((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const submitForm = async (event) => {
    event.preventDefault();
    let { name, lastname, email, company, amount, gender, password, releaseDate } = state;
    let bigValue = Number(amount);
    let formattedValue = String(bigValue.toLocaleString());

    email = email.toLowerCase();
    axios
      .post(
        "https://astorgrp.vercel.app/user",
        {
          name: name,
          lastname: lastname,
          email: email,
          company,
          releaseDate,
          amount: formattedValue,
          gender,
          password,
        },
        {
          headers: {
            "x-access-token": token?.token,
          },
        }
      )
      .then((response) => {
        const data = response.data;
        if (data?.message === "Success!") {
          setsuccess("true");
          seterror("User created successfully");
        } else {
          setsuccess("false");
          seterror(data?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      {token?.token === null ? (
        <>
          <Navigate replace to="/adminlogin" />;
        </>
      ) : (
        <>
          <Navbar />
          <div class="form_wrapper">
            <div class="form_container">
              {success === "true" ? (
                <>
                  <div class="alert alert-primary" role="alert">
                    You have successfully registered a user
                  </div>
                </>
              ) : null}
              {success === "false" ? (
                <>
                  <div class="alert alert-danger" role="alert">
                    {error}
                  </div>
                </>
              ) : null}
              <div class="title_container">
                <h2>Register User</h2>
              </div>
              <div class="row clearfix">
                <div class="">
                  <form>
                    <div class="row clearfix">
                      <div class="col_half">
                        <div class="input_field">
                          {" "}
                          <span>
                            <i aria-hidden="true" class="fa fa-user"></i>
                          </span>
                          <input
                            type="text"
                            onChange={setValues}
                            value={state.name}
                            name="name"
                            placeholder="First Name"
                          />
                        </div>
                      </div>
                      <div class="col_half">
                        <div class="input_field">
                          {" "}
                          <span>
                            <i aria-hidden="true" class="fa fa-user"></i>
                          </span>
                          <input
                            onChange={setValues}
                            value={state.lastname}
                            type="text"
                            name="lastname"
                            placeholder="Last Name"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="input_field">
                      {" "}
                      <span>
                        <i aria-hidden="true" class="fa fa-envelope"></i>
                      </span>
                      <input
                        onChange={setValues}
                        value={state.email}
                        type="email"
                        name="email"
                        placeholder="Email"
                        required
                      />
                    </div>
                    {/* <div class="input_field">
                        {" "}
                        <span>
                          <i class="fa-solid fa-door-open"></i>
                        </span>
                        <input
                          onChange={setValues}
                          value={state.merchant}
                          type="text"
                          name="merchant"
                          placeholder="Merchant Name"
                          required
                        />
                      </div> */}
                    <div class="input_field">
                      {" "}
                      <span>
                        <i aria-hidden="true" class="fa fa-calendar"></i>
                      </span>
                      <input
                        onChange={setValues}
                        value={state.releaseDate}
                        type="date"
                        name="releaseDate"
                        placeholder="Release Date"
                      />
                    </div>
                    <div class="input_field">
                      {" "}
                      <span>
                        <i class="fa-sharp fa-solid fa-building"></i>
                      </span>
                      <input
                        onChange={setValues}
                        value={state.company}
                        type="text"
                        name="company"
                        placeholder="Company Name"
                        required
                      />
                    </div>
                    <div class="input_field input-balance">
                      {" "}
                      <span>
                        <i class="fa-solid fa-money-bill"></i>
                      </span>
                      <input
                        onChange={setValues}
                        value={state.amount}
                        type="number"
                        name="amount"
                        // min="1"
                        placeholder="Enter Amount in $"
                      />
                      <div className="amount">$</div>
                    </div>
                    <div class="input_field">
                      {" "}
                      <span>
                        <i aria-hidden="true" class="fa fa-lock"></i>
                      </span>
                      <input
                        onChange={setValues}
                        value={state.password}
                        type="password"
                        name="password"
                        placeholder="Password"
                        required
                      />
                    </div>
                    {/* <div class="input_field">
                        {" "}
                        <span>
                          <i class="fa-solid fa-user-secret"></i>
                        </span>
                        <input
                          type="text"
                          name="secretkey"
                          onChange={setValues}
                          value={state.secretkey}
                          placeholder="Secret key(only available for admin)"
                          required
                        />
                      </div> */}
                    <div class="input_field radio_option">
                      <input
                        type="radio"
                        name="gender"
                        id="rd1"
                        onChange={setValues}
                        checked={state.gender === "male"}
                        value="male"
                      />
                      <label for="rd1">Male</label>
                      <input
                        type="radio"
                        name="gender"
                        id="rd2"
                        checked={state.gender === "female"}
                        onChange={setValues}
                        value="female"
                      />
                      <label for="rd2">Female</label>
                    </div>
                    {/* <div class="input_field select_option">
                  <select>
                    <option>Select a country</option>
                    <option>Option 1</option>
                    <option>Option 2</option>
                  </select>
                  <div class="select_arrow"></div>
                </div> */}
                    <div class="input_field checkbox_option">
                      <input type="checkbox" id="cb1" />
                      <label for="cb1">I agree with terms and conditions</label>
                    </div>
                    {/* <div class="input_field checkbox_option">
                        <input
                          type="checkbox"
                          id="cb2"
                          name="checkbox"
                          onChange={setValues}
                          onClick={changeCheckedValue}
                          value={checkedValue}
                          checked={(state.checkbox = checkedValue)}
                        />
                        <label for="cb2">I want to receive the newsletter</label>
                      </div> */}
                    <input
                      onClick={submitForm}
                      class="button"
                      type="submit"
                      value="Register"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* <p class="credit">
              Developed by{" "}
              <a href="http://www.madeviser.com" target="_blank">
                Madeviser
              </a>
            </p> */}
        </>
      )}
    </>
  );
}

export default RegistrationForm;
