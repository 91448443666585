import React, { useState, useEffect } from "react";
import { useNavigate} from "react-router-dom";
// import { database } from "./firebaseconfig";
// import { ref, set, get, child } from "firebase/database";
import axios from "axios";
import "./register.css";
import { LC2 } from "../assets/images/index";
function Login() {


  const navigate = useNavigate();
  const [dbData, setData] = useState({});
  let [state, setState] = useState({
    email: "",
    password: "",
    checkbox: false,
  });
  const [success, setsuccess] = useState("");
  const [error, seterror] = useState("");
  const [id, setid] = useState("");
  

  const setValues = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setState((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };


  const submitSignInForm = async (event) => {
    event.preventDefault();
    let { email, password } = state;
    email = email.toLowerCase();
    await axios.post("https://astorgrp.vercel.app/user/userlogin", { email, password })
    .then((response) => {
        if (!response?.data?.token) {
            setsuccess("false")
            seterror(response?.data?.message);
        }
        else {
            localStorage.setItem("adminWrightInvestmentGrpUser", JSON.stringify(response?.data));
            navigate("/dashboard");
        }
    });
  };
  return (
    <>
      <div className="container-fluid logo-box">
        <div className="row logo-img">
          <img src={LC2} alt="" srcset="" />
        </div>
      </div>
      <div class="form_wrapper">
        <div class="form_container">
          {success === "false" ? (
            <>
              <div class="alert alert-danger" role="alert">
                {error}
              </div>
            </>
          ) : null}
          <div class="title_container">
            <h2>Portal Login </h2>
          </div>
          <div class="row clearfix">
            <div class="">
              <form>
                <div class="input_field">
                  {" "}
                  <span>
                    <i aria-hidden="true" class="fa fa-envelope"></i>
                  </span>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    onChange={setValues}
                    value={state.email}
                    required
                  />
                </div>
                <div class="input_field">
                  {" "}
                  <span>
                    <i aria-hidden="true" class="fa fa-lock"></i>
                  </span>
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    onChange={setValues}
                    value={state.password}
                    required
                  />
                </div>
                <input class="button" onClick={submitSignInForm} type="submit" value="Login" />
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <p class="credit">
        Developed by{" "}
        <a href="http://www.madeviser.com" target="_blank">
          Madeviser
        </a>
      </p> */}
    </>
  );
}

export default Login;
