import React from "react";
import "./register.css"
import { useNavigate, Link } from "react-router-dom";
import { LC2 } from "../assets/images/index";
function Navbar() {
    const navigate = useNavigate();
    const logout = (e) => {
        e.preventDefault();
        localStorage.removeItem("adminWrightInvestmentGrp");
        navigate("/adminlogin");
    }
    return (
        <>
            <nav class="navbar navbar-expand-lg">
                <div className="row navbar-logo-img">
                    <img src={LC2} alt="" srcset="" />
                </div>
                <button className="navbar-toggler toggle_btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                    <div class="navbar-nav">
                        <Link class="nav-item nav-link active" to="/register">Home <span class="sr-only">(current)</span></Link>
                        <Link class="nav-item nav-link" to="/user">All Users</Link>
                        <Link onClick={logout} class="nav-item nav-link" to="#">Logout</Link>
                        {/* <a class="nav-item nav-link disabled" href="#">Disabled</a> */}
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Navbar;
