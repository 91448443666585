import React, { useEffect, useState } from "react";
import { Navigate, Link } from "react-router-dom";
import "./dashboard.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import "./user.css";
function User() {
  const [key, setkey] = useState();
  const [popup, setpopup] = useState(false);
  const [data, setdata] = useState({});

  let [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .get("https://astorgrp.vercel.app/user")
      .then((response) => {
        if (response?.data) {
          setdata(response?.data);
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {});
  });

  const navigate = useNavigate();
  let token = JSON.parse(localStorage.getItem("adminWrightInvestmentGrp"));

  const deleteValue = (dataKey) => {
    setpopup(true);
    window.scroll(0, 10);
    setkey(dataKey);
  };
  const checkValue = (event) => {
    event.preventDefault();
    console.log(event.target.value);
    if (event.target.value === "yes") {
      axios.delete(`https://astorgrp.vercel.app/user/${key}`, {
        headers: {
          "x-access-token": token?.token,
        },
      });
    }
    setpopup(false);
  };
  return (
    <>
      {!token?.token ? (
        <>
          <Navigate replace to="/adminlogin" />;
        </>
      ) : (
        <>
          <Navbar />

          <div class="container-fluid mt-4 pt-4 px-5">
            {popup ? (
              <div className="popup">
                <p>Are you sure you want to delete this user ?</p>
                <button
                  onClick={checkValue}
                  value="yes"
                  className="btn btn-danger me-4"
                >
                  Yes
                </button>
                <button
                  onClick={checkValue}
                  value="no"
                  className="btn btn-secondary"
                >
                  No
                </button>
              </div>
            ) : null}
            <h1 class="container-title my-4 text-center px-6"></h1>
            <table>
              <caption>Statement Summary</caption>
              <thead>
                <tr className="headline">
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Company</th>
                  <th scope="col">Gender</th>
                  <th scope="col">Release Date</th>
                  <th scope="col">Operations</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(data).map((key, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <td data-label="Name">
                          {data[key]?.name} {data[key]?.lastname}
                        </td>
                        <td data-label="Email">{data[key]?.email}</td>
                        <td data-label="Amount">${data[key]?.amount}.00</td>
                        <td data-label="Company">{data[key]?.company}</td>
                        <td data-label="Gender">{data[key]?.gender}</td>
                        <td data-label="Release Date">
                          {data[key]?.releaseDate && (
                            <>{data[key]?.releaseDate.slice(0, 10)}</>
                          )}
                        </td>
                        <div
                          data-label="Operations"
                          className="text-center btn-box"
                        >
                          <Link to={`/edit/${data[key]?._id}`}>
                            <button className="btn btn-primary mt-1 mx-4">
                              Edit
                            </button>
                          </Link>
                          <button
                            onClick={() => {
                              deleteValue(data[key]?._id);
                            }}
                            className="btn btn-primary mt-1"
                          >
                            Delete
                          </button>
                        </div>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
}
export default User;
