import React, { useState, useEffect } from "react";
import { useNavigate, Link, Navigate, useParams } from "react-router-dom";
import axios from "axios";
import "./register.css";
import Navbar from "./Navbar";
function Edit({ dbData }) {
  const [uid, setuid] = useState();
  let token = JSON.parse(
    localStorage.getItem("adminWrightInvestmentGrp")
  ).token;
  let [state, setState] = useState({
    name: "",
    lastname: "",
    email: "",
    company: "",
    amount: "",
    password: "",
    gender: "",
    releaseDate: "",
  });
  const [success, setsuccess] = useState("");
  const [error, seterror] = useState("");
  const setValues = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setState((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const { key } = useParams();
  useEffect(() => {
    setuid(key);
    axios
      .get(`https://astorgrp.vercel.app/user/${uid}`)
      .then((response) => {
        if (response?.data) {
          const data = response.data;
          let newamount = data.amount.replace(",", "");
          // newamount = newamount.replace(".","");
          // Check if releaseDate exists and format it to YYYY-MM-DD
          let formattedDate = data.releaseDate
            ? new Date(data.releaseDate).toISOString().split("T")[0]
            : ""; // If releaseDate is missing, set it as an empty string
          setState({
            name: data.name,
            lastname: data.lastname,
            email: data.email,
            gender: data.gender,
            company: data.company,
            amount: newamount,
            password: data.password,
            releaseDate: formattedDate,
          });
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {});
  }, [1, uid]);

  const submitForm = async (event) => {
    event.preventDefault();
    let {
      name,
      lastname,
      email,
      company,
      amount,
      gender,
      password,
      releaseDate,
    } = state;
    let bigValue = Number(amount);
    let formattedValue = String(bigValue.toLocaleString());
    // amount = formattedValue + ".00";
    email = email.toLowerCase();
    axios
      .patch(
        `https://astorgrp.vercel.app/user/${uid}`,
        {
          name: name,
          lastname: lastname,
          email: email,
          company,
          amount: formattedValue,
          gender,
          password,
          releaseDate,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      )
      .then((response) => {
        console.log(response);
        const data = response.data;
        if (data?.message === "Success!") {
          setsuccess("true");
          seterror("User edited successfully");
        } else {
          setsuccess("false");
          seterror(data?.message);
        }
      });
  };
  return (
    <>
      {token === null ? (
        <>
          <Navigate replace to="/adminlogin" />;
        </>
      ) : (
        <>
          <Navbar />
          <div class="form_wrapper">
            <div class="form_container">
              {success === "true" ? (
                <>
                  <div class="alert alert-primary" role="alert">
                    You have successfully Update a user
                  </div>
                </>
              ) : null}
              {success === "false" ? (
                <>
                  <div class="alert alert-danger" role="alert">
                    {error}
                  </div>
                </>
              ) : null}
              <div class="title_container">
                <h2>Register User</h2>
              </div>
              <div class="row clearfix">
                <div class="">
                  <form>
                    <div class="row clearfix">
                      <div class="col_half">
                        <div class="input_field">
                          {" "}
                          <span>
                            <i aria-hidden="true" class="fa fa-user"></i>
                          </span>
                          <input
                            type="text"
                            onChange={setValues}
                            value={state.name}
                            name="name"
                            placeholder="First Name"
                          />
                        </div>
                      </div>
                      <div class="col_half">
                        <div class="input_field">
                          {" "}
                          <span>
                            <i aria-hidden="true" class="fa fa-user"></i>
                          </span>
                          <input
                            onChange={setValues}
                            value={state.lastname}
                            type="text"
                            name="lastname"
                            placeholder="Last Name"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="input_field">
                      {" "}
                      <span>
                        <i aria-hidden="true" class="fa fa-envelope"></i>
                      </span>
                      <input
                        onChange={setValues}
                        value={state.email}
                        type="email"
                        name="email"
                        placeholder="Email"
                        required
                      />
                    </div>
                    <div class="input_field">
                      {" "}
                      <span>
                        <i aria-hidden="true" class="fa fa-calendar"></i>
                      </span>
                      <input
                        onChange={setValues}
                        value={state.releaseDate}
                        type="date"
                        name="releaseDate"
                        placeholder="Release Date"
                      />
                    </div>
                    <div class="input_field">
                      {" "}
                      <span>
                        <i class="fa-sharp fa-solid fa-building"></i>
                      </span>
                      <input
                        onChange={setValues}
                        value={state.company}
                        type="text"
                        name="company"
                        placeholder="Company Name"
                        required
                      />
                    </div>
                    <div class="input_field input-balance">
                      {" "}
                      <span>
                        <i class="fa-solid fa-money-bill"></i>
                      </span>
                      <input
                        onChange={setValues}
                        value={state.amount}
                        type="number"
                        name="amount"
                        // min="1"
                        placeholder="Enter Amount in $"
                      />
                      <div className="amount">$</div>
                    </div>
                    <div class="input_field">
                      {" "}
                      <span>
                        <i aria-hidden="true" class="fa fa-lock"></i>
                      </span>
                      <input
                        onChange={setValues}
                        value={state.password}
                        type="password"
                        name="password"
                        placeholder="Password"
                        required
                      />
                    </div>
                    <div class="input_field radio_option">
                      <input
                        type="radio"
                        name="gender"
                        id="rd1"
                        onChange={setValues}
                        checked={state.gender === "male"}
                        value="male"
                      />
                      <label for="rd1">Male</label>
                      <input
                        type="radio"
                        name="gender"
                        id="rd2"
                        checked={state.gender === "female"}
                        onChange={setValues}
                        value="female"
                      />
                      <label for="rd2">Female</label>
                    </div>

                    <div class="input_field checkbox_option">
                      <input type="checkbox" id="cb1" />
                      <label for="cb1">I agree with terms and conditions</label>
                    </div>
                    <input
                      onClick={submitForm}
                      class="button"
                      type="submit"
                      value="Update"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* <p class="credit">
                            Developed by{" "}
                            <a href="http://www.madeviser.com" target="_blank">
                                Madeviser
                            </a>
                        </p> */}
        </>
      )}
    </>
  );
}

export default Edit;
