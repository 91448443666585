import React, {useState}from "react";
import Dashboard from "./Dashboard";
import RegistrationForm from "./RegistrationForm";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./Login";
import "./style.css";
import AdminLogin from "./AdminLogin";
import User from "./User";
import Edit from "./Edit";
function App() {


  const [dbData, setData] = useState({});

  const router = createBrowserRouter([
    {
      path: "/",
      element:
        <>
          <Dashboard />
        </>
      // errorElement: <Nopage />,
    },
    {
      path: "/dashboard",
      element:
        <>
          <Dashboard/>
        </>
      // errorElement: <Nopage />,
    },
    {
      path: "/register",
      element:
        <>
          <RegistrationForm dbData={dbData} setData={setData}/>
        </>

      // errorElement: <Nopage />,
    },
    {
      path: "login",
      element:
        <>
          <Login />
        </>
      ,
      // errorElement: <Nopage />,
    },
    {
      path: "user",
      element:
        <>
          <User />
        </>
      ,
      // errorElement: <Nopage />,
    },
    {
      path: "edit/:key",
      element:
        <>
          <Edit dbData={dbData} setData={setData}/>
        </>
      ,
      // errorElement: <Nopage />,
    },
    {
      path: "adminlogin",
      element:
        <>
          <AdminLogin />
        </>
      ,
      // errorElement: <Nopage />,
    }
  ]);
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
