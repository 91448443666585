import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./register.css";
import axios from "axios";
import { LC2 } from "../assets/images/index";
function AdminLogin() {

    const navigate = useNavigate();

    const [state, setState] = useState({
        email: "",
        password: ""
    });
    const [success, setsuccess] = useState("");
    const [error, seterror] = useState("");

    const setValues = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        setState((prevValue) => {
            return {
                ...prevValue,
                [name]: value,
            };
        });
    };
    const submitSignInForm = async (event) => {
        event.preventDefault();
        let { email, password } = state;
        email = email.toLowerCase();
        await axios.post("https://astorgrp.vercel.app/user/login", { email, password })
            .then((response) => {
                if (!response?.data?.token) {
                    setsuccess("false")
                    seterror(response?.data?.message);
                }
                else {
                    localStorage.setItem("adminWrightInvestmentGrp", JSON.stringify(response?.data));
                    navigate("/register");
                }
            });
    };
    return (
        <>
            <div className="container-fluid logo-box">
                <div className="row logo-img">
                    <img src={LC2} alt="" srcSet="" />
                </div>
            </div>
            <div className="form_wrapper">
                <div className="form_container">
                    {success === "false" ? (
                        <>
                            <div className="alert alert-danger" role="alert">
                                {error}
                            </div>
                        </>
                    ) : null}
                    <div className="title_container">
                        <h2>Admin Login</h2>
                    </div>
                    <div className="row clearfix">
                        <div className="">
                            <form>
                                <div className="input_field">
                                    {" "}
                                    <span>
                                        <i aria-hidden="true" className="fa fa-envelope"></i>
                                    </span>
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        onChange={setValues}
                                        value={state.email}
                                        required
                                    />
                                </div>
                                <div className="input_field">
                                    {" "}
                                    <span>
                                        <i aria-hidden="true" className="fa fa-lock"></i>
                                    </span>
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                        onChange={setValues}
                                        value={state.password}
                                        required
                                    />
                                </div>
                                <input className="button" onClick={submitSignInForm} type="submit" value="Login" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* <p className="credit">
                Developed by{" "}
                <a href="http://www.madeviser.com" target="_blank">
                    Madeviser
                </a>
            </p> */}
        </>
    );
}

export default AdminLogin;
