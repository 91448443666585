import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { LC2 } from "../assets/images/index";
import "./dashboard.css"
import { useNavigate } from "react-router-dom";
function Dashboard() {
  let data = JSON.parse(localStorage.getItem('adminWrightInvestmentGrpUser'));
  const [userInfo, setuserInfo] = useState({
    name: data?.message?.name,
    lastname: data?.message?.lastname,
    email: data?.message?.email,
    company: data?.message?.company,
    amount: data?.message?.amount,
    gender: data?.message?.gender,
  });
  const [popup, setpopup] = useState(false)

  const navigate = useNavigate();

  function logOut(e) {
    e.preventDefault();
    localStorage.removeItem("adminWrightInvestmentGrpUser");
    navigate("/login");
  }
  const withdraw = () => {
    setpopup(true);
  }
  const closepopup = (event) => {
    event.preventDefault();
    setpopup(false);
  }

  return (
    <>
      {
        !data ? <>
          <Navigate replace to="/login" />;
        </> :
          <>
            <div className="dashboard">
              <div className="container-fluid logo-box">
                <div className="row logo-img">
                  <img src={LC2} alt="" srcset="" />
                </div>
              </div>
              <div className="container-fluid">
                {popup ? <div className="popup">
                  <p>Please contact info@astorgrp.com</p>
                  <button onClick={closepopup} className="btn btn-warning">OK</button>
                </div> : null}
                <div className="row">
                  <div className="col-lg-3 col-sm-12 col-md-6 left-box">
                    <div className="my-4 usericon">
                      <i class="fa-solid fa-user"></i>
                    </div>
                    <div className="user-info">
                      <p>{userInfo.name} {userInfo.lastname}</p>
                      <p>{userInfo.email}</p>
                      <p>{userInfo.company}</p>
                      {/* <p>{userInfo.gender}</p> */}
                      <div>
                        <button onClick={logOut} className="btn btn-primary my-2">Log out</button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9 col-sm-12 col-md-6 right-box">
                    <div className="container innerbox">
                      <div className="row ">
                        <div className="col-lg-1 "></div>
                        <div className="col-lg-10 mb-4">
                          <div className="total-amount-box">
                            <h3 className="heading">Total amount : ${userInfo.amount}.00 </h3>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-1 col-sm-12"></div>
                        <div className="col-lg-5 col-sm-12">
                          <div className="box1 boxex">
                            <i class="fa-solid fa-sack-dollar mb-4 big-icon"></i>
                            <h3>Available Credit</h3>
                            <p>${userInfo.amount}.00</p>
                          </div>
                          <div className="box2 boxex">
                            <i class="fa-solid fa-coins mb-4 big-icon"></i>
                            <h3>Due amount</h3>
                            <p>$0.00</p>
                          </div>
                        </div>
                        <div className="col-lg-5 col-sm-12 box3 boxex">
                          <i class="fa-solid fa-hand-holding-dollar mb-4 big-icon"></i>
                          <h3>Withdraw</h3>
                          <div class="form_wrapper dashboard-form">
                            <div class="form_container">
                              <div class="row clearfix">
                                <div class="">
                                  <div class="input_field input-balance dashboard-form-money-feild">
                                    {" "}
                                    <span>
                                      <i class="fa-solid fa-money-bill"></i>
                                    </span>
                                    <input
                                      type="number"
                                      name="amount"
                                      // min="1"
                                      placeholder="Enter Amount in $"
                                    />
                                    <div className="amount">$</div>
                                  </div>
                                  <div class="input_field select_option">
                                    <select>
                                      <option>Select payment method</option>
                                      <option>ACH</option>
                                      <option>WIRE</option>
                                    </select>
                                    <div class="select_arrow"></div>
                                  </div>
                                  <input
                                    onClick={withdraw}
                                    class="button mt-4"
                                    type="submit"
                                    value="Continue"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
      }
    </>
  );
}
export default Dashboard;
